import React, { useContext } from "react";
import {
  Box,
  Grid,
  Typography,
  Button,
  useTheme,
  useMediaQuery,
  CircularProgress,
  Stack,
} from "@mui/material";
import { MyContext } from "../Context/websiteinfocontext";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Logi1 from "../assets/brandlogo/Kit-Cat-New-Logo_150pxH.png"
import Logi2 from "../assets/brandlogo/Ultra_Pet_350x.webp"
import Logi3 from "../assets/brandlogo/newNaturoLogo24.png"
import Logi4 from "../assets/brandlogo/new-logo_250.webp"

const SpecialSale = () => {
  const { special_sale } = useContext(MyContext);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const string = "SPECIAL";

  const getformattedDate = (unparsedate) => {
    const options = { year: "numeric", month: "short", day: "numeric" };
    const date = new Date(
      unparsedate !== undefined && unparsedate?.replace(/\//g, "-")
    );
    const formattedDate = date.toLocaleDateString("en-GB", options);
    return formattedDate;
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <Box sx={{ borderRadius: "10px", overflow: "hidden"  }}>
      <Grid container>
        {/* First container: Carousel */}
        <Grid
          item
          xs={12}
          md={4}
          sx={{ width: { md: "33.33%", sm: "33.33%", lg: "33.33%" },maxHeight:"500px" }}
        >
          <Slider {...settings}>
            <Box
              component="img"
              src="/sps1.png"
              alt="Special Sale"
              sx={{ width: "100%", maxHeight:"400px", objectFit: "fill" }}
            />
            <Box
              component="img"
              src="/sps2.png"
              alt="Special Sale"
              sx={{ width: "100%", maxHeight:"400px", objectFit: "fill" }}
            />
            <Box
              component="img"
              src="/sps5.png"
              alt="Special Sale 2"
              sx={{ width: "100%", maxHeight:"400px", objectFit: "fill" }}
            />
          </Slider>
        </Grid>

        <Grid
          item
          xs={12}
          md={3}
          sx={{ width: { md: "33.33%", sm: "33.33%", lg: "33.33%" }, maxHeight: "400px" }}
        >
          <Stack
            direction="column"
            spacing={2}
            alignItems="center"
            sx={{ height: "100%", justifyContent: "center" }}
          >
            <Box
              component="img"
              src={Logi1}
              alt="Logo 1"
              sx={{ width: "80%", maxHeight: "100px", objectFit: "contain" ,margin:"auto"}}
            />
            <Box
              component="img"
              src={Logi4}
              alt="Logo 2"
              sx={{ width: "80%", maxHeight: "100px", objectFit: "contain",margin:"auto" }}
            />
            <Box
              component="img"
              src={Logi3}
              alt="Logo 3"
              sx={{ width: "80%", maxHeight: "100px", objectFit: "contain",margin:"auto" }}
            />
           {/* <Button
                  variant="contained"
                  sx={{
                    marginTop: 3,
                    backgroundColor: "#000",
                    color: "#fff",
                    fontSize: isSmallScreen ? "0.75rem" : "0.875rem",
                    fontFamily: "poppins",
                    "&:hover": {
                      backgroundColor: "#333",
                    },
                  }}
                  onClick={() =>
                    window.open("https://thevetstore.webbieshop.com/", {
                      target: "_blank",
                    })
                  }
                >
                  SHOP NOW
                </Button> */}
          </Stack>
        </Grid>

        {special_sale.special_sale_text === undefined ? (
          <Grid
            item
            xs={12}
            md={5}
            sx={{ width: { md: "33.33%", sm: "33.33%", lg: "33.33%" } ,maxHeight:"400px", }}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress color="primary" style={{ color: "#ff6501" }} />
          </Grid>
        ) : (
          <Grid
            item
            xs={12}
            md={5}
            sx={{ width: { md: "33.33%", sm: "33.33%", lg: "33.33%" } }}
          >
            <Box
              sx={{
                backgroundColor: "#f4f6f5",
                color: "#000",
                paddingLeft: 5,
                pt:2,
                pb:2,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-start",
                height: "100%",
              }}
            >
              <Typography
                variant="h4"
                sx={{
                  fontWeight: "bold",
                  fontSize: isSmallScreen ? "1.5rem" : "3rem",
                  fontFamily: "poppins",
                  background: "#ff6501",
                  transform: "rotate(-3deg)",
                }}
              >
                {string}
              </Typography>
              <Typography
                variant="h4"
                sx={{
                  marginTop: 1,
                  fontWeight: "bold",
                  fontSize: isSmallScreen ? "1.5rem" : "3rem",
                  fontFamily: "poppins",
                }}
              >
                SALE
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  marginTop: 2,
                  fontWeight: "500",
                  fontSize: {
                    xs: "1rem",
                    sm: "1.3rem",
                    md: "1.1rem",
                    lg: "1.6rem",
                  },
                  fontFamily: "poppins",
                }}
              >
                {special_sale.special_sale_text ?special_sale.special_sale_text:"Don't miss out" }
              </Typography>
            { (special_sale?.special_sale_start_date && special_sale?.special_sale_end_date) ? <Typography
                variant="body2"
                sx={{
                  marginTop: 3,
                  fontWeight: "700",
                  fontSize: {
                    xs: "1rem",
                    sm: "1.2rem",
                    md: "1.6rem",
                    lg: "1.8rem",
                  },
                  fontFamily: "poppins",
                }}
              >
                {getformattedDate(special_sale?.special_sale_start_date) } to{" "}
                {getformattedDate(special_sale?.special_sale_end_date)}
              </Typography>: <Typography
                variant="body2"
                sx={{
                  marginTop: 3,
                  fontWeight: "700",
                  fontSize: {
                    xs: "1rem",
                    sm: "1.2rem",
                    md: "1.6rem",
                    lg: "1.8rem",
                  },
                  fontFamily: "poppins",
                }}
              >
                {"21 Aug 2024 to 29 Aug 2024"}
               </Typography>}
              <Typography
                variant="body2"
                sx={{
                  fontSize: {
                    xs: "0.8rem",
                    sm: "1rem",
                    md: "1.2rem",
                    lg: "1.4rem",
                  },
                  fontFamily: "poppins",
                }}
              >
                *Terms & Conditions apply
              </Typography>
              <Grid>
                <Button
                  variant="contained"
                  sx={{
                    marginTop: 3,
                    backgroundColor: "#000",
                    color: "#fff",
                    fontSize: isSmallScreen ? "0.75rem" : "0.875rem",
                    fontFamily: "poppins",
                    "&:hover": {
                      backgroundColor: "#333",
                    },
                  }}
                  onClick={() =>
                    window.open("https://thevetstore.webbieshop.com/", {
                      target: "_blank",
                    })
                  }
                >
                  SHOP NOW
                </Button>
              </Grid>
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default SpecialSale;
