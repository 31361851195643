import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Link } from "react-router-dom";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
const FrameComponent1 = ({ className = "", icons = [] }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [anchorElins, setAnchorElins] = useState(null);
  const [anchorElmenu, setAnchorElmenu] = useState(null);

  const handleClick = (event) => {
    setAnchorElmenu(event.currentTarget);
  };

  const handleClosemenu = () => {
    setAnchorElmenu(null);
  };
  const handleMouseEnterins = (event) => {
    setAnchorElins(event.currentTarget);
  };

  const handleMouseLeaveins = () => {
    setAnchorElins(null);
  };

  const openforinsurance = Boolean(anchorElins);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMouseEnter = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMouseLeave = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const navLinks = [
    { text: "Categories", href: "https://thevetstore.webbieshop.com/" },
    // { text: "Voucher", href: "#" }
  ];

  return (
    <AppBar
      position="static"
      sx={{
        backgroundColor: "#fff",
        color: "#000",
        boxShadow: "none",
      }}
    >
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: { xs: "0 8px", sm: "0 16px" },
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Link
            to={"/"}
            style={{
              textDecoration: "none",
              color: "#000",
              fontSize: "1.4rem",
              fontWeight: "bold",
              fontFamily: "poppins",
            }}
          >
            <Box
              component="img"
              src="https://webbie.s3.af-south-1.amazonaws.com/website_logos/logo_1.jpg"
              alt="Hero Illustration"
              sx={{
                width: { xs: 40, sm: 70 },
                marginRight: 1.5,
              }}
            />
          </Link>
          <Link
            to={"/"}
            style={{
              textDecoration: "none",
              color: "#000",
              fontSize: "1.4rem",
              fontWeight: "bold",
              fontFamily: "poppins",
            }}
          >
            The Vet Store
          </Link>
        </Box>
        <Box
          sx={{
            display: { xs: "none", md: "flex" },
            alignItems: "center",
            ml: "auto",
            mr: 2,
          }}
        >
          <Link
            key={"About us"}
            to={"/about"}
            style={{
              textDecoration: "none",
              marginRight: "20px",
              color: "#000",
            }}
            sx={{
              textDecoration: "none",
              color: "#000",
              fontSize: { xs: "14px", sm: "16px", md: "18px" },
            }}
          >
            {"About us"}
          </Link>

          <Link
            key={"Categories"}
            href={"https://thevetstore.webbieshop.com/"}
            onClick={() =>
              window.open("https://thevetstore.webbieshop.com/", {
                target: "_blank",
              })
            }
            style={{
              textDecoration: "none",
              marginRight: "20px",
              color: "#000",
            }}
            sx={{
              textDecoration: "none",
              color: "#000",
              fontSize: { xs: "14px", sm: "16px", md: "18px" },
            }}
          >
            {"Categories"}
          </Link>
          <Link
            key={"Our Store"}
            to={"/ourstores"}
            // onClick={()=>window.open(link.href,{target:"_blank"})}
            style={{
              textDecoration: "none",
              marginRight: "20px",
              color: "#000",
            }}
            sx={{
              textDecoration: "none",
              color: "#000",
              fontSize: { xs: "14px", sm: "16px", md: "18px" },
            }}
          >
            {"Vet Stores"}
          </Link>
          <Link
            key={"Our Clinics"}
            to={"/ourclinics"}
            style={{
              textDecoration: "none",
              marginRight: "20px",
              color: "#000",
            }}
            sx={{
              textDecoration: "none",
              color: "#000",
              fontSize: { xs: "14px", sm: "16px", md: "18px" },
            }}
          >
            {"Vet Clinics"}
          </Link>

          {/* <Link
            key={"Our Team"}
            to={"/"}
            style={{
              textDecoration: "none",
              marginRight: "20px",
              color: "#000",
            }}
            sx={{
              textDecoration: "none",
              color: "#000",
              fontSize: { xs: "14px", sm: "16px", md: "18px" },
            }}
          >
            {"Pet Insurance "}
          </Link> */}

          <div onMouseLeave={handleMouseLeaveins}>
            <Link
              component={Link}
              to="/contactus"
              onMouseEnter={handleMouseEnterins}
              style={{
                textDecoration: "none",
                marginRight: "20px",
                color: "#000",
              }}
              sx={{
                textDecoration: "none",
                marginRight: "20px",
                color: "#000",
                fontSize: { xs: "14px", sm: "16px", md: "18px" },
                cursor: "pointer",
              }}
            >
              Pet Insurance
            </Link>
            <Menu
              anchorEl={anchorElins}
              open={openforinsurance}
              onClose={handleMouseLeaveins}
              MenuListProps={{
                onMouseLeave: handleMouseLeaveins,
              }}
              PaperProps={{
                elevation: 3,
                sx: { mt: 1, minWidth: "150px" },
              }}
            >
              <MenuItem
                // component={Button}
                onClick={() => {
                  window.open("https://pet.dotsure.co.za/", "_blank");
                }}
                sx={{
                  textDecoration: "none",
                  color: "#000",
                  fontSize: "16px",
                  "&:hover": {
                    backgroundColor: "#f1f1f1",
                  },
                }}
              >
                {"Dotsure"}
              </MenuItem>
              <MenuItem
                // component={Button}
                onClick={() => {
                  window.open("https://medipet.co.za/add-a-pet/", "_blank");
                }}
                sx={{
                  textDecoration: "none",
                  color: "#000",
                  fontSize: "16px",
                  "&:hover": {
                    backgroundColor: "#f1f1f1",
                  },
                }}
              >
                MediPet
              </MenuItem>
            </Menu>
          </div>

          <div onMouseLeave={handleMouseLeave}>
            <Link
              component={Link}
              to="/contactus"
              onMouseEnter={handleMouseEnter}
              style={{
                textDecoration: "none",
                marginRight: "20px",
                color: "#000",
              }}
              sx={{
                textDecoration: "none",
                marginRight: "20px",
                color: "#000",
                fontSize: { xs: "14px", sm: "16px", md: "18px" },
                cursor: "pointer",
              }}
            >
              Contact us
            </Link>
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleMouseLeave}
              MenuListProps={{
                onMouseLeave: handleMouseLeave,
              }}
              PaperProps={{
                elevation: 3,
                sx: { mt: 1, minWidth: "150px" },
              }}
            >
              <MenuItem
                component={Link}
                to="/contactus"
                sx={{
                  textDecoration: "none",
                  color: "#000",
                  fontSize: "16px",
                  "&:hover": {
                    backgroundColor: "#f1f1f1",
                  },
                }}
              >
                {"Contact us"}
              </MenuItem>
              <MenuItem
                component={Link}
                to="/ourteams"
                sx={{
                  textDecoration: "none",
                  color: "#000",
                  fontSize: "16px",
                  "&:hover": {
                    backgroundColor: "#f1f1f1",
                  },
                }}
              >
                Our Team
              </MenuItem>
            </Menu>
          </div>
          {/* <Link
            key={"Contact us"}
            to={"/contactus"}
            style={{
              textDecoration: "none",
              marginRight: "20px",
              color: "#000",
            }}
            sx={{
              textDecoration: "none",
              color: "#000",
              fontSize: { xs: "14px", sm: "16px", md: "18px" },
            }}
          >
            {"Contact us"}
          </Link> */}
        </Box>

        <Drawer anchor="right" open={drawerOpen} onClose={handleDrawerToggle}>
          <List sx={{ width: 250 }}>
            {navLinks.map((link) => (
              <ListItem
                button
                key={link.text}
                target="_blank"
                rel="noopener noreferrer"
                component="a"
                href={link.href}
              >
                <ListItemText primary={link.text} />
              </ListItem>
            ))}
            <Link
              to={"/ourstores"}
              style={{ textDecoration: "none", color: "#2b2b2b" }}
            >
              <ListItem button>
                <ListItemText primary={"Vet Store"} />
              </ListItem>
            </Link>
            <Link
              to={"/ourstores"}
              style={{ textDecoration: "none", color: "#2b2b2b" }}
            >
              <ListItem button>
                <ListItemText primary={"Vet Clinics"} />
              </ListItem>
            </Link>
            {/* <Link to={"/ourstores"} style={{textDecoration:"none", color:"#2b2b2b"}}>
              <ListItem button>
                <ListItemText primary={"Pet Insurance"} />
              </ListItem>
            </Link> */}
            <>
              <Button
                aria-controls="pet-insurance-menu"
                aria-haspopup="true"
                onClick={handleClick}
                endIcon={
                  Boolean(!anchorElmenu) ? (
                    <ArrowDropDownIcon />
                  ) : (
                    <ArrowDropUpIcon />
                  )
                }
                variant="text"
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  color: "#2B2B2B",
                  pl: 2,
                  textTransform: "none",
                  fontSize: "1rem",
                  fontWeight: "400",
                }}
              >
                {"Pet Insurance"}
              </Button>
              <Menu
                id="pet-insurance-menu"
                anchorEl={anchorElmenu}
                open={Boolean(anchorElmenu)}
                onClose={handleClosemenu}
              >
                <MenuItem onClick={handleClosemenu}>
                  <Link
                    to="/ourstores"
                    style={{ textDecoration: "none", color: "#2b2b2b" }}
                  >
                    <ListItem button>
                      <ListItemText primary={"Dotsure"} />
                    </ListItem>
                  </Link>
                </MenuItem>
                <MenuItem>
                  <MenuItem
                    // component={Button}
                    onClick={() => {
                      window.open("https://medipet.co.za/add-a-pet/", "_blank");
                    }}
                    sx={{
                      textDecoration: "none",
                      color: "#000",
                      fontSize: "16px",
                      "&:hover": {
                        backgroundColor: "#f1f1f1",
                      },
                    }}
                  >
                    MediPet
                  </MenuItem>
                </MenuItem>
              </Menu>
            </>

            <Link
              to={"/ourteams"}
              style={{ textDecoration: "none", color: "#2b2b2b" }}
            >
              <ListItem
                button
                // to={"/ourteams"}
              >
                <ListItemText primary={"Our Team"} />
              </ListItem>
            </Link>
            <Link
              to={"/about"}
              style={{ textDecoration: "none", color: "#2b2b2b" }}
            >
              <ListItem
                button
                // to={"/ourteams"}
              >
                <ListItemText primary={"About us"} />
              </ListItem>
            </Link>
            <Link
              to={"/contactus"}
              style={{ textDecoration: "none", color: "#2b2b2b" }}
            >
              <ListItem
                button
                // to={"/ourteams"}
              >
                <ListItemText primary={"Contact us"} />
              </ListItem>
            </Link>
          </List>
        </Drawer>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#000",
              fontSize: { xs: "14px", sm: "16px", md: "18px" },
              padding: { xs: "3px 12px", sm: "4px 16px", md: "4px 20px" },
              "&:hover": {
                backgroundColor: "#333",
              },
              mr: 2,
            }}
            onClick={() =>
              window.open("https://thevetstore.webbieshop.com/", {
                target: "_blank",
              })
            }
          >
            SHOP
          </Button>
          <IconButton
            edge="end"
            color="inherit"
            aria-label="menu"
            sx={{ display: { xs: "flex", md: "none" } }}
            onClick={handleDrawerToggle}
          >
            <MenuIcon style={{ fontSize: "30px" }} />
          </IconButton>
          {/* {icons.length > 0 && (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {icons.map((Icon, index) => (
                <IconButton key={index} sx={{ color: "#000", ml: 1 }}>
                  <SvgIcon component={Icon} />
                </IconButton>
              ))}
            </Box>
          )} */}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

FrameComponent1.propTypes = {
  className: PropTypes.string,
  icons: PropTypes.arrayOf(PropTypes.elementType), // Accepts an array of icon components
};

export default FrameComponent1;
