import PropTypes from "prop-types";
import styles from "./BrandContainer.module.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Liones from "../assets/brandlogo/30e531_a92afde23a894f038d884800a8e219f7~mv2.webp"
const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const brandimagescontainer = [
  {
    name: "Montego Pet Nutrition ",
    uri:require( "../assets/brandlogo/Montego_350x.webp"),
    pathurl: "https://www.montego.co.za/",
    categoryname:"Montego"
  },
  {
    name: "Hills",
    uri:require( "../assets/brandlogo/Hill_s_Science_Plan_Logo_350x.webp"),
    pathurl: "https://www.hillspet.co.za/",
    categoryname:"Hills"
  },
  {
    name: "Acana",
    uri: require( "../assets/brandlogo/Acana_Logo_350x.webp"),
    pathurl: "https://emea.acana.com/en/homepage",
    categoryname:"Acana"
  },
  {
    name: "Royal Canin",
    uri: require( "../assets/brandlogo/Royal_Canin_350x.webp"),
    pathurl: "https://www.royalcanin.com/za",
    categoryname:"Royal Canin"
  },
  {
    name: "IAMS",
    uri:require( "../assets/brandlogo/IAMS white RGB_0.webp"),
    pathurl: "https://www.iams.com/",
    categoryname:"IAMS"
  },
  {
    name: "Ultra Pet",
    uri:require( "../assets/brandlogo/Ultra_Pet_350x.webp"),
    pathurl: "https://ultra-pet.co.za/",
    categoryname:"Ultra Pet"
  },
  {
    name: "Naturo Natural Pet Food",
    uri:require( "../assets/brandlogo/newNaturoLogo24.png"),
    pathurl: "https://www.naturopetfoods.com/",
    categoryname:"Naturo"
  },
  {
    name: "Lionels Choice",
    uri: require( "../assets/brandlogo/30e531_a92afde23a894f038d884800a8e219f7~mv2.webp"),
    pathurl: "https://www.lionelschoice.com/",
    categoryname:"Lionel's Choice"
  },
  {
    name: "Omega Pet Food",
    uri: require( "../assets/brandlogo/new-logo_250.webp"),
    pathurl: "https://www.omegapetfoods.com/",
    categoryname:"Omega"
  },
  {
    name: "Eukanuba",
    uri: require( "../assets/brandlogo/Eukanuba_350x.webp"),
    pathurl: "https://www.eukanuba.com/za",
    categoryname:"Eukanuba"
  },
  {
    name: "Kitty Cat",
    uri: require( "../assets/brandlogo/Kit-Cat-New-Logo_150pxH.png"),
    pathurl: "https://kitcat.com.sg/products/wet-food/",
    categoryname:"Kit Kat"
  },
];




const images = [
  // "/rectangle-36_2x-removebg-preview.png",
  // "/rectangle-44_2x-removebg-preview.png",
  "/rectangle-38_2x-removebg-preview.png",
  // "/rectangle-45_2x-removebg-preview (1).png",
  // "/rectangle-43_2x-removebg-preview.png",
  // "/rectangle-41_2x-removebg-preview.png"
];

const BrandContainer = ({ className = "" }) => {
  return (
    <Carousel
      swipeable={false}
      draggable={false}
      showDots={false}
      responsive={responsive}
      ssr={true}
      infinite={true}
      autoPlay={true}
      autoPlaySpeed={5000}
      keyBoardControl={true}
      customTransition="all 1"
      transitionDuration={500}
      containerClass="carousel-container"
      removeArrowOnDeviceType={["tablet","desktop", "mobile"]}
      dotListClass="custom-dot-list-style"
      itemClass="carousel-item-padding-40-px"
      className={className} // Apply className if needed
    >
      {/* backgroundColor: "rgba(255, 255, 255, 0.3)", */}
      {brandimagescontainer.map((src, index) => (
        <div style={{cursor:"pointer", backgroundColor: "#ff6501"}} onClick={()=>window.open(`https://thevetstore.webbieshop.com/products?category=${src.categoryname}`,{target:"_blank"})}>
        <img
          key={index}
          loading="lazy"
          alt={`Brand ${index + 1}`}
          src={src.uri}
          className={styles.image} 
          />
          </div>
      ))}
    </Carousel>
  );
};

BrandContainer.propTypes = {
  className: PropTypes.string,
};

export default BrandContainer;
